import React from 'react';
import { getImageURL } from '../constants';

export default function ImageCarousel({Images}) {
  return (
    Images && Images.length > 0 && (
      <div className="carousel-container">
        {/* Render a scrollable carousel with images displayed side by side */}
        <div className="scrollable-carousel">
          {Images.map((image, index) => (
            <div className="carousel-item" key={index}>
              <a href={getImageURL(image)} target="_blank" rel="noopener noreferrer">
                <img src={getImageURL(image)} alt={`Image ${index + 1}`} />
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  );
}