import axios from 'axios';
import { API_SCAN_SEND, API_SCAN_SEND_TIKTOK, API_URL_EXHIBITS_TAGS, DESTINATION_TYPES_ENUM, getSingleExhibitURL } from './constants';

export async function SendExhibitRequest(data, channelID, exhibitType, tiktokData) {

   const DestinationTikTok = channelID.toLowerCase() === DESTINATION_TYPES_ENUM.TIKTOK.toLowerCase();
  console.log("DestinationTikTok: ", DestinationTikTok);
  const exhibitSendRequestData = DestinationTikTok ? {
    exhibit_id: data,
    image_index: tiktokData.image_index,
    rectangle_x: tiktokData.rectangle_x,
    rectangle_y: tiktokData.rectangle_y,
    rectangle_width: tiktokData.rectangle_width,
    rectangle_height: tiktokData.rectangle_height,
    image_x: tiktokData.image_x,
    image_y: tiktokData.image_y,
    image_width: tiktokData.image_width,
    image_height: tiktokData.image_height,
  } : {
    data,
    channel_id: channelID,
    exhibit_type: exhibitType,
  };
    console.log(exhibitSendRequestData);
    const target_url = (DestinationTikTok ? API_SCAN_SEND_TIKTOK : API_SCAN_SEND) + "/" + data;

  try {
    const response = await axios.post(target_url, exhibitSendRequestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    //throw error;
  }
}

export async function GetExhibitRequest(exhibitID) {

  try {
    const response = await axios.get(getSingleExhibitURL(exhibitID));

    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    throw error;
  }
}

export async function GetTagsRequest() {
  
    try {
      const response = await axios.get(API_URL_EXHIBITS_TAGS);
  
      return response.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
      throw error;
    }
}

// Helper function to convert number to Base27
function toBase27(num) {
  const chars = '0123456789ABCDEFGHIJKLMNOP'; // 27 characters
  let result = '';
  while (num > 0) {
      result = chars[num % 27] + result;
      num = Math.floor(num / 27);
  }
  return result;
}

// Helper function to scramble string based on permutation
function scrambleWithPermutation(str, permutation) {
  return str.split('').map((char, i) => str[permutation[i]]).join('');
}

export function encodeTimestampWithRandomness() {
  const unixTime = Math.floor(Date.now() / 1000); // Get Unix timestamp in seconds
  const base27Time = toBase27(unixTime);
  const base64Time = btoa(base27Time);

  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomPrefix = '';
  for (let i = 0; i < 5; i++) {
      randomPrefix += randomChars[Math.floor(Math.random() * randomChars.length)];
  }

  const permutations = [
      [1, 3, 0, 2, 4], 
      [3, 1, 4, 2, 0], 
      [4, 3, 0, 1, 2], 
      [2, 4, 1, 0, 3], 
      [0, 2, 3, 4, 1]
  ];
  
  const randomPermutation = permutations[Math.floor(Math.random() * permutations.length)];

  const encodedString = randomPrefix + base64Time;
  const scrambledString = scrambleWithPermutation(encodedString, randomPermutation);

  return scrambledString;
}