import React, { useRef } from "react";
import {
  Card,
  Heading,
  Content,
  Paragraph,
  ButtonGroup,
  Button,
  TrashMajorIcon,
  Text,
  Box,
  as,
  CrossMajorIcon,
  Modal,
  ModalTrigger,
  TextArea,
  Field,
  Label,
  TextInput,
  HelpMessage,
  FileMajorIcon,
  Switch,
} from "@sharegate/orbit-ui";
import { useEffect, useState } from "react";
import axios from "axios";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import "./Scan.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import {
  API_URL_IMAGE_UPLOAD,
  getImageURL,
  getImageMetaURL,
  getSingleExhibitURL,
  API_URL_EXHIBITS,
} from "../constants";
import crypto from "crypto-js";

const generateFileHashes = async (files) => {
  if (!files || files.length === 0) {
    return [[], {}];
  }
  let fileHashPromises = files.map((file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        let arrayBuffer = e.target.result;
        let wordArray = crypto.lib.WordArray.create(arrayBuffer);
        let hash = crypto.SHA256(wordArray);
        resolve({ hash: hash.toString(crypto.enc.Hex), file });
      };
      reader.onerror = function (e) {
        reject(new Error("Failed to read file data."));
      };
      reader.readAsArrayBuffer(file.data);
    });
  });

  let fileHashes = await Promise.all(fileHashPromises);

  let fileHashMap = {};
  let hashArray = [];
  fileHashes.forEach(({ hash, file }) => {
    fileHashMap[hash] = file;
    hashArray.push(hash);
  });

  return [hashArray, fileHashMap];
};

const uploadNewFiles = async (oldImageHashes, currentStagedFiles) => {
  let newFileHashes = Object.keys(currentStagedFiles).filter(
    (hash) => !oldImageHashes.includes(hash)
  );

  let uploadPromises = newFileHashes.map(async (hash) => {
    let file = currentStagedFiles[hash].data;

    let formData = new FormData();
    formData.append("name", file.name);
    formData.append("fileType", file.type);
    formData.append("image", file);

    let response = await axios.post(API_URL_IMAGE_UPLOAD, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  });

  let uploadResponses = await Promise.all(uploadPromises);

  return uploadResponses;
};

export const FullScanEdit = ({
  textData,
  updateCardFunction,
  deleteCardFunction,
  onClose,
}) => {
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false); // Add a state variable to track loading
  const [deleteButtonIsLoading, setDeleteButtonIsLoading] = useState(false); // Add a state variable to track loading

  const handleClose = () => {
    onClose(); // Call the onClose function to close the FullScan
  };

  const Div = as(Box, "div");

  const {
    exhibitId,
    images,
    englishTranslation,
    arabicText,
    reference,
    book,
    link,
    tags,
    relatedExhibits,
    notes,
  } = textData;

  const englishTranslationRef = useRef(null);
  const arabicTextRef = useRef(null);
  const referenceRef = useRef(null);
  const linkRef = useRef(null);
  const tagsRef = useRef(null);
  const bookRef = useRef(null);
  const relatedExhibitsRef = useRef(null);
  const notesRef = useRef(null);
  const publicRef = useRef(textData.Public);

  console.log(textData);

  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 10,
      allowedFileTypes: ["image/*"],
      maxFileSize: 1024 * 1024 * 50,
    },
  });

  async function downloadAndAddImages(images) {
    if (!images || images.length === 0) {
      return;
    }
    let i = 0;
    for (let image of images) {
      try {
        // Download image from URL
        const response = await axios.get(getImageURL(image), {
          responseType: "arraybuffer",
        });

        // Download image metadata from URL
        const responseMeta = await axios.get(getImageMetaURL(image));
        let imageMeta = responseMeta.data;
        const imageData = new Blob([response.data]);

        uppy.addFile({
          source: "Local",
          name: imageMeta.Name,
          type: imageMeta.FileType,
          data: imageData,
        });
        i++;
      } catch (error) {
        console.error(`Error downloading or adding image: ${error}`);
      }
    }
  }

  downloadAndAddImages(images);

  const SaveScan = async () => {
    // Prepare the payload in the required format
    let exhibitData = {
      ExhibitID: exhibitId,
      "English Translation": englishTranslationRef.current.value,
      "Arabic Text": arabicTextRef.current.value,
      Reference: referenceRef.current.value,
      Link: linkRef.current.value,
      Tags: tagsRef.current.value.split(","), // Split tags string into an array
      Book: bookRef.current.value,
      "Related Exhibits": [], // Split related exhibits string into an array
      Notes: notesRef.current.value,
      Public: publicRef.current,
    };
    const images = uppy.getFiles();

    setSaveButtonIsLoading(true); // Start loading
    let response; // Declare response here
    let [hashArray, fileHashMap] = await generateFileHashes(images);
    exhibitData.Images = hashArray;

    let responses = await uploadNewFiles(images, fileHashMap);
    console.log(exhibitData);
    try {
      // Make a POST request here, replace the URL and data with your API endpoint and data
      response = await axios.post(API_URL_EXHIBITS, exhibitData);
    } catch (error) {
      console.error(error);
    } finally {
      setSaveButtonIsLoading(false); // End loading when the request finishes, whether it fails or succeeds
      if (response) {
        let currentExhibitData = response.data;
        updateCardFunction(currentExhibitData); // Call the updateCardFunction to update the card
        textData = currentExhibitData; // Update the textData variable
      }
    }
  };

  const DeleteScan = async () => {
    // Prepare the payload in the required format

    setDeleteButtonIsLoading(true); // Start loading
    let response; // Declare response here
    try {
      // Make a POST request here, replace the URL and data with your API endpoint and data
      response = await axios.delete(getSingleExhibitURL(exhibitId));
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteButtonIsLoading(false); // End loading when the request finishes, whether it fails or succeeds
      if (response) {
        let currentExhibitData = response.data;
        deleteCardFunction(response.data); // Call the updateCardFunction to update the card
        textData = currentExhibitData; // Update the textData variable
        onClose();
      }
    }
  };

  return (
    <Div className="fullscan-container-div">
      <Dashboard
        uppy={uppy}
        theme="dark"
        hideUploadButton={true}
        hideCancelButton={true}
        fileManagerSelectionType={"files"}
      />
      <br />
      <Heading>Exhibit {exhibitId}</Heading>
      <Content>
        <Div padding={"5px"} margin={"10px"}>
          <Field fluid>
            <Label>English Translation</Label>
            <TextArea
              ref={englishTranslationRef}
              placeholder="Narrated 'Umar bin Al-Khattab: I heard Allah's Messenger (ﷺ) saying, 'The reward of deeds depends upon the intentions and every person will get the reward according to what he has intended. So whoever emigrated for worldly benefits or for a woman to marry, his emigration was for what he emigrated for.'"
              defaultValue={englishTranslation}
            />
            <HelpMessage>
              Translation of the Arabic text into English.
            </HelpMessage>
          </Field>
          <br />
          <br />
          <Field fluid>
            <Label>Arabic Text</Label>
            <TextArea
              ref={arabicTextRef}
              placeholder="Arabic text"
              defaultValue={arabicText}
            />
            <HelpMessage>Arabic Text of the scan.</HelpMessage>
          </Field>
          <br />
          <br />
          <Field fluid>
            <Label>Reference</Label>
            <TextInput
              ref={referenceRef}
              placeholder="Sahih al-Bukhari Book 1 Hadith 1"
              defaultValue={reference}
            />
            <HelpMessage>Reference for the scan.</HelpMessage>
          </Field>
          <br />
          <br />
          <Field fluid>
            <Label>Book</Label>
            <TextInput
              ref={bookRef}
              placeholder="Sahih al-Bukhari"
              defaultValue={book}
            />
            <HelpMessage>Book from where the scan was made.</HelpMessage>
          </Field>
          <br />
          <br />
          <Field fluid>
            <Label>Link</Label>
            <TextInput
              ref={linkRef}
              placeholder="https://sunnah.com/bukhari:1"
              defaultValue={link}
            />
            <HelpMessage>
              Link to PDF of the book or to the scan itself.
            </HelpMessage>
          </Field>
          <br />
          <br />
          <Field fluid>
            <Label>Tags</Label>
            <TextInput
              ref={tagsRef}
              placeholder="intentions, omar, actions"
              defaultValue={tags}
            />
            <HelpMessage>
              Some tags with which to easily look up the narration.
            </HelpMessage>
          </Field>
          <br />
          <br />
          <Paragraph>
            <strong>Related Exhibits:</strong> {relatedExhibits}
          </Paragraph>
          <br />
          <br />
          <Field fluid>
            <Label>Notes</Label>
            <TextArea
              ref={notesRef}
              placeholder="This hadith can be used to show that actions of a person cannot be held against them if they are ignorant of the sin or incorrectness of their actions."
              defaultValue={notes}
            />
            <HelpMessage>
              Any notes that go along with this scan if used.
            </HelpMessage>
          </Field>
          <br />
          <br />
          <Field >
            <Switch defaultChecked={textData.Public} onChange={() => {
              publicRef.current = !publicRef.current;
            }}>
              Public
            </Switch>
          </Field>

          <br />
          <br />
        </Div>
      </Content>
      <ButtonGroup>
        <Button loading={saveButtonIsLoading} onClick={SaveScan}>
          <FileMajorIcon />
          <Text>Save</Text>
        </Button>
        <Button variant="negative" onClick={handleClose}>
          <CrossMajorIcon />
          <Text>Close</Text>
        </Button>
        {exhibitId && (
          <ModalTrigger>
            <Button variant="negative">
              <TrashMajorIcon />
              <Text>Delete</Text>
            </Button>
            <Modal>
              <Heading>Delete Scan</Heading>
              <Content>Are you sure you want to delete this scan?</Content>
              <ButtonGroup>
                <Button
                  loading={deleteButtonIsLoading}
                  variant="negative"
                  onClick={DeleteScan}
                >
                  <TrashMajorIcon />
                  <Text>Delete</Text>
                </Button>
              </ButtonGroup>
            </Modal>
          </ModalTrigger>
        )}
      </ButtonGroup>
    </Div>
  );
};

export default FullScanEdit;
