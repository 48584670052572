import {
  Text,
  Button,
  Header,
  SignoutMajorIcon,
  HomeMajorIcon,
  Avatar,
  Inline,
  TextInput,
  ChevronMajorIcon,
  Div,
  CrossButton,
  Badge,
  AddMajorIcon,
  MenuTrigger,
  IconButton,
  Menu,
  Item,
  DisclosureArrow,
} from "@sharegate/orbit-ui";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import "./Header.css";
import { DESTINATION_TYPES_ENUM } from "../constants";

const DiscordChannelBar = ({ discordChannel, setDiscordChannel, setDestinationType }) => {
  const clearInput = () => {
    setDiscordChannel("");
    setDestinationType(DESTINATION_TYPES_ENUM.DISCORD);
  };

  return (
    <TextInput
      value={discordChannel}
      onChange={(e) => {
        setDiscordChannel(e.target.value);
        if (e.target.value === DESTINATION_TYPES_ENUM.TIKTOK) {
          setDestinationType(DESTINATION_TYPES_ENUM.TIKTOK);
        } else {
          setDestinationType(DESTINATION_TYPES_ENUM.DISCORD);
        }
      }}
      button={<CrossButton aria-label="Clear value" onClick={clearInput} />}
      fluid
      icon={<ChevronMajorIcon />}
      placeholder="Discord Channel"
    />
  );
};

const StickyHeader = (AppData) => {
  const navigate = useNavigate();
  return (
    <Header className="sticky-header">
      <div className="left-section">
        <Button
          className="menu-button"
          onClick={() => {
            navigate("/");
          }}
        >
          <HomeMajorIcon />
          <Text>Home</Text>
        </Button>
        <MenuTrigger>
          <Button
            variant="secondary"
            className="menu-button"
            aria-label="Scans"
          >
            <Text>Scans </Text>
            <DisclosureArrow slot="end-icon" />
          </Button>
          <Menu>
            <Item
              key="myscansbutton"
              onClick={() => {
                navigate("/app/scans/my");
              }}
            >
              My Scans
            </Item>
            <Item
              key="sharedscansbutton"
              onClick={() => {
                navigate("/app/scans/shared");
              }}
            >
              Shared Scans
            </Item>
            <Item
              key="publicscansbutton"
              onClick={() => {
                navigate("/app/scans/public");
              }}
            >
              Public Scans
            </Item>
            <Item
              key="allscansbutton"
              onClick={() => {
                navigate("/app/scans/all");
              }}
            >
              All Scans
            </Item>
          </Menu>
        </MenuTrigger>

        <MenuTrigger>
          <Button
            variant="secondary"
            className="menu-button"
            aria-label="Topics"
          >
            <Text>Topics</Text>
            <DisclosureArrow slot="end-icon" />
          </Button>
          <Menu>
            <Item
              key="myargsbutton"
              onClick={() => {
                navigate("/app/topics/my");
              }}
            >
              My Topics
            </Item>
            <Item
              key="allargsbutton"
              onClick={() => {
                navigate("/app/topics/shared");
              }}
            >
              Shared Topics
            </Item>
            <Item
              key="mysyllsbutton"
              onClick={() => {
                navigate("/app/topics/public");
              }}
            >
              Public Topics
            </Item>
            <Item
              key="allsyllsbutton"
              onClick={() => {
                navigate("/app/topics/all");
              }}
            >
              All Topics
            </Item>
          </Menu>
        </MenuTrigger>

        <MenuTrigger>
          <Button
            variant="secondary"
            className="menu-button"
            aria-label="Groups"
          >
            <Text>Groups</Text>
            <DisclosureArrow slot="end-icon" />
          </Button>
          <Menu>
            <Item
              key="mygroupsbutton"
              onClick={() => {
                navigate("/app/groups/my");
              }}
            >
              My Groups
            </Item>
            <Item
              key="allgroupssbutton"
              onClick={() => {
                navigate("/app/groups/shared");
              }}
            >
              Shared Groups
            </Item>
            <Item
              key="mysyllsbutton"
              onClick={() => {
                navigate("/app/groups/public");
              }}
            >
              Public Groups
            </Item>
            <Item
              key="allgroupssbutton"
              onClick={() => {
                navigate("/app/groups/all");
              }}
            >
              All Groups
            </Item>
          </Menu>
        </MenuTrigger>
      </div>

      <div className="right-section">
        <Inline alignY="center">
          <Div className="wide-c">
            {" "}
            <DiscordChannelBar
              discordChannel={AppData.discordChannel}
              setDiscordChannel={AppData.setDiscordChannel}
              setDestinationType={AppData.setDestinationType}
            />{" "}
          </Div>
          <Avatar src={AppData.user.avatar_url} name="avatar" />

          <Text style={{ fontWeight: "bold" }}>{AppData.user.username}</Text>

          <Button
            onClick={() => {
              document.cookie =
                "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.reload();
            }}
          >
            {" "}
            <SignoutMajorIcon />
            <Text>Logout</Text>
          </Button>
        </Inline>
      </div>
    </Header>
  );
};

export default StickyHeader;
