import { createThemeVars, Div, ShareGateTheme, ThemeProvider } from "@sharegate/orbit-ui";
import { createRoot } from "react-dom/client";
import * as React from 'react';

import App from "./App";

createThemeVars([ShareGateTheme]);

const root = createRoot(document.getElementById("root")!);
root.render(
    <ThemeProvider theme={ShareGateTheme} colorScheme="dark">
        <Div style={{position: "fixed", top: "0", left: "0", width: "100%", height: "100%"}} backgroundColor={"#111010"}>

        <App />
        </Div>
    </ThemeProvider>
);
