// Devlare global constants here
export const BASE_URL = '';
export const API_URL = BASE_URL + '/api';


export const API_URL_EXHIBITS = `${API_URL}/exhibits`;
export const API_URL_EXHIBITS_ALL = `${API_URL}/exhibits/all`;
export const API_URL_EXHIBITS_MY = `${API_URL}/exhibits/my`;
export const API_URL_EXHIBITS_PUBLIC = `${API_URL}/exhibits/public`;
export const API_URL_SINGLE_EXHIBIT = `${API_URL}/exhibits/single`;
export const API_URL_EXHIBITS_TAGS = `${API_URL}/exhibits/tags`;

export const API_URL_TOPICS = `${API_URL}/topics`;
export const API_URL_IMAGE = `${API_URL}/images`;
export const API_URL_IMAGE_META = `${API_URL}/image_meta`;
export const API_URL_IMAGE_UPLOAD = `${API_URL}/upload`
export const API_SCAN_SEND = `${API_URL_EXHIBITS}/send_scan`
export const API_SCAN_SEND_TIKTOK = `${API_URL_EXHIBITS}/send_scan_tiktok`

export const PUBLIC_IMAGE_ENDPOINT = `/images`;

const protocol = window.location.host.includes('localhost') ? 'http' : 'https';
export const DISCORD_LOGIN_URL = `https://discord.com/api/oauth2/authorize?client_id=1130428535882915860&redirect_uri=${encodeURIComponent(`${protocol}://${window.location.host}/login`)}&response_type=code&scope=identify%20guilds`;

export const USER_URL = `${BASE_URL}/user`;

// Function to convert image hash to image URL
export const getImageURL = (hash) => {
    return `${PUBLIC_IMAGE_ENDPOINT}/${hash}`;
    }

export const getImageMetaURL = (hash) => {
    return `${API_URL_IMAGE_META}/${hash}`;
}

export const getSingleExhibitURL = (exhibitID) => {
    return `${API_URL_SINGLE_EXHIBIT}/${exhibitID}`;
}

export const getTopicURL = (topicID) => {
    return `${API_URL_TOPICS}/${topicID}`;
}


export const PAGES_ENUM = {
    HOME: 0,
    ALL_SCANS: 1,
}

export const EXHIBIT_TYPES_ENUM = {
    SCAN: "SCAN_TYPE",
    TEXT: "TEXT_TYPE",
}

export const DESTINATION_TYPES_ENUM = {
    DISCORD: "discord",
    TIKTOK: "tiktok",
}