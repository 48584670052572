import "@sharegate/orbit-ui/index.css";
import { useEffect, useState } from "react";
import {
  H1,
  Text,
  Lozenge,
  Card,
  Heading,
  Content,
  Paragraph,
  Div,
  ButtonGroup,
  Button,
  PencilMajorIcon,
  ArrowMajorIcon,
  CrossMajorIcon,
} from "@sharegate/orbit-ui";
import ScanCard from "../ExpandedScan/ScanCard";
import { GetExhibitRequest } from "../SharedFunctions";
import ScanMention from "./ScanMention";
import { EXHIBIT_TYPES_ENUM } from "../constants";


function processText(inputText) {
  const regex = /(<Exhibit\s([A-Za-z0-9]+)>)/g;
  let resultArray = [];
  let match;
  let lastIdx = 0;

  while ((match = regex.exec(inputText)) !== null) {
    let [
      matchedText,
      exhibitCard,
      exhibitCardId,
      exhibitMention,
      exhibitMentionId,
    ] = match;

    // Extract the plain text before the match and add it to the result if it's not empty
    const plainText = inputText.slice(lastIdx, match.index).trim();
    if (plainText !== "") {
      resultArray.push({ type: "PlainText", content: plainText });
    }

    // Determine whether it's 'ExhibitCard' or 'ExhibitMention' and add it to the result
    if (exhibitCard) {
      resultArray.push({ type: "ExhibitCard", content: exhibitCardId });
    } else if (exhibitMention) {
      resultArray.push({ type: "ExhibitMention", content: exhibitMentionId });
    }

    // Update the last index for the next iteration
    lastIdx = regex.lastIndex;
  }

  // Add the remaining text after the last match as plain text
  const remainingText = inputText.slice(lastIdx).trim();
  if (remainingText !== "") {
    resultArray.push({ type: "PlainText", content: remainingText });
  }

  return resultArray;
}

const ScanCardRequest = ({ exhibitID, sendScanFunction }) => {
  exhibitID = exhibitID.padStart(6, "0");
  const [data, setData] = useState(null); // Create a state variable to hold your data
  useEffect(() => {
    const fetchData = async () => {
      const resp = await GetExhibitRequest(exhibitID); // Use the prop exhibitID
      setData(resp); // Save the response to your state variable
    };

    fetchData();
  }, [exhibitID]); // Add exhibitID as a dependency, so the effect runs again if it changes

  // Make sure not to render ScanCard until data is available
  return (
    <Div style={{ padding: "10px" }}>
      {data && (
        <ScanCard
          textData={data}
          minimized={true}
          sendExhibit={sendScanFunction}
          useModal={true}
        />
      )}
    </Div>
  );
};

const TextComponent = (content) => {
  return (
    <>
      {processTextContent(content.content).map((item, index) => {
        switch (item.type) {
          case "PlainText":
            return <Text key={`${item.type}-${index}`}
            >{item.content}</Text>;
          case "ExhibitMention":
            return <ScanMention item={item} exhibitID={item.content} key={`${item.type}-${index}`}
            />;
          default:
            return <Text key={`${item.type}-${index}`}>{item.content}</Text>;
        }
      })}
    </>
  );
}

function processTextContent(inputText) {
  const regex = /(Exhibit\s([A-Za-z0-9]+))/g;
  let resultArray = [];
  let match;
  let lastIdx = 0;

  while ((match = regex.exec(inputText)) !== null) {
    let [
      matchedText,
      exhibitMention,
      exhibitMentionId,
    ] = match;

    // Extract the plain text before the match and add it to the result if it's not empty
    const plainText = inputText.slice(lastIdx, match.index).trim();
    if (plainText !== "") {
      resultArray.push({ type: "PlainText", content: plainText });
    }
    if (exhibitMention) {
      resultArray.push({ type: "ExhibitMention", content: exhibitMentionId });
    }

    // Update the last index for the next iteration
    lastIdx = regex.lastIndex;
  }

  // Add the remaining text after the last match as plain text
  const remainingText = inputText.slice(lastIdx).trim();
  if (remainingText !== "") {
    resultArray.push({ type: "PlainText", content: remainingText });
  }

  return resultArray;
}

export const TopicView = (AppData) => {
  const TopicData = AppData.TopicData;

  const handleClose = () => {
    AppData.onClose(); // Call the onClose function to close the FullScan
  };

  const processedText = processText(TopicData.Text);

  return (
    <Div className="fullscan-container-div">
      <H1>{TopicData.Title}</H1>
      {processedText.map((item, index) => {
        let prevItem = processedText[index - 1];
        let nextItem = processedText[index + 1];

        switch (item.type) {
          case "PlainText":
            let content = item.content;

            if (prevItem && prevItem.type === "ExhibitMention") {
              // Add an extra space at the start if preceded by a lozenge
              content = " " + content;
            }

            if (nextItem && nextItem.type === "ExhibitMention") {
              // Add an extra space at the end if followed by a lozenge
              content = content + " ";
            }

            return (
              <Card key={`${item.type}-${index}`}>
                <Heading></Heading>
                <Content>
                  <Paragraph>
                    <TextComponent content={content} />

                  </Paragraph>
                </Content>
                <ButtonGroup>
                  <Button variant="primary" onClick={() => AppData.sendExhibit(content, EXHIBIT_TYPES_ENUM.TEXT)}>
                    <ArrowMajorIcon />
                    <Text>Send</Text>
                  </Button>
                  </ButtonGroup>
              </Card>
            );

          case "ExhibitMention":
            return <ScanMention item={item} exhibitID={item.content} key={`${item.type}-${index}`} />;

          case "ExhibitCard":
            return (
              <ScanCardRequest
                key={`${item.type}-${index}`}
                exhibitID={item.content}
                sendScanFunction={AppData.sendExhibit}
              />
            );

          default:
            return null;
        }
      })}
      <br />
      <br />
      <ButtonGroup>
      <Button variant="secondary" onClick={() => AppData.editModeFunction(TopicData)}>
          <PencilMajorIcon />
          <Text>Edit</Text>
        </Button>
        <Button variant="negative" onClick={handleClose}>
          <CrossMajorIcon />
          <Text>Close</Text>
        </Button>
      </ButtonGroup>
    </Div>
  );
};
