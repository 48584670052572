import '@sharegate/orbit-ui/index.css';
import '../../App.css';
import { useState } from "react";
import { Div, TextInput, Button, H1 } from '@sharegate/orbit-ui';
import './LoginPage.css';
import { DISCORD_LOGIN_URL } from '../../constants';

export const LoginPage = () => {
  const [discordAuthToken, setDiscordAuthToken] = useState();
      // Function for redirection
  const redirectToCustomUrl = () => {
    window.location.href = DISCORD_LOGIN_URL;
  };
  return (
    <Div className="login-container">
      <img src="https://i.imgur.com/nuKakPp.png" alt="Scan Database Logo" />
      <br />
      <Div className="login-card">
        <H1>Scan Database Login</H1>
        <br />
        <Button onClick={redirectToCustomUrl}>Log In</Button>
      </Div>
    </Div>
  );
};

export default LoginPage;
