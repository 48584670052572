import React, { useRef } from "react";
import {
  Card,
  Heading,
  Content,
  Paragraph,
  ButtonGroup,
  Button,
  Header,
  Image,
  Illustration,
  PencilMajorIcon,
  EyeMajorIcon,
  ArrowMajorIcon,
  Text,
  Box,
  as,
  CrossMajorIcon,
} from "@sharegate/orbit-ui";
import "./Scan.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import {
  DESTINATION_TYPES_ENUM,
  EXHIBIT_TYPES_ENUM,
  getImageURL,
} from "../constants";
import ImageCarousel from "./Carousel";
import { Exhibit } from "types/Exhibit";
import Viewfinder from "./Viewfinder";

// Define types for the props
interface FullScanProps {
  textData: Exhibit;
  editModeFunction: (data: Exhibit) => void;
  sendScanFunction: (exhibitId: string, exhibitType: string) => void;
  onButtonClick?: () => void;
  onClose: () => void;
  destinationType: string;
}

const FullScan: React.FC<FullScanProps> = ({
  textData,
  editModeFunction,
  sendScanFunction,
  onButtonClick,
  onClose,
  destinationType,
}) => {
  const handleClose = () => {
    onClose(); // Call the onClose function to close the FullScan
  };

  const Div = as(Box, "div");

  const {
    exhibitId,
    images,
    englishTranslation,
    arabicText,
    reference,
    book,
    link,
    tags,
    relatedScans,
    notes,
  } = textData;

  return (
    <Div className="fullscan-container-div">
      {destinationType === DESTINATION_TYPES_ENUM.DISCORD ? (
        <ImageCarousel Images={images} />
      ) : (
        <Viewfinder
          url={getImageURL(images[0])}
          canvasWidth={1280}
          canvasHeight={720}
          exhibitId={exhibitId}
          imageIndex={0}
        />
      )}

      <Heading>Exhibit {exhibitId}</Heading>
      <Content>
        <Div padding={"5px"} margin={"10px"}>
          <Paragraph>
            <strong>English Translation:</strong> {englishTranslation}
          </Paragraph>
          <Paragraph>
            <strong>Arabic Text:</strong> {arabicText}
          </Paragraph>
          <Paragraph>
            <strong>Reference:</strong> {reference}
          </Paragraph>
          <Paragraph>
            <strong>Book:</strong> {book}
          </Paragraph>
          <Paragraph>
            <strong>Link:</strong>{" "}
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "lightgrey" }}
            >
              {link}
            </a>
          </Paragraph>
          <Paragraph>
            <strong>Tags:</strong> {tags.join(", ")}
          </Paragraph>
          <Paragraph>
            <strong>Related Exhibits:</strong> {relatedScans.join(", ")}
          </Paragraph>
          <Paragraph>
            <strong>Notes:</strong> {notes}
          </Paragraph>
        </Div>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onClick={() => editModeFunction(textData)}>
          <PencilMajorIcon />
          <Text>Edit</Text>
        </Button>
        <Button
          onClick={() =>
            sendScanFunction(textData.exhibitId, EXHIBIT_TYPES_ENUM.SCAN)
          }
        >
          <ArrowMajorIcon />
          <Text>Send</Text>
        </Button>
        <Button variant="negative" onClick={handleClose}>
          <CrossMajorIcon />
          <Text>Close</Text>
        </Button>
      </ButtonGroup>
    </Div>
  );
};

export { FullScan };
