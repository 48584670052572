import React, { useState, useEffect, useRef } from "react";

import { Stage, Layer, Image, Rect, Transformer } from "react-konva";
import useImage from "use-image";
import { DESTINATION_TYPES_ENUM, EXHIBIT_TYPES_ENUM } from "../constants";
import { SendExhibitRequest } from "../SharedFunctions";
import "./Viewfinder.css";

const Viewfinder = ({
  url,
  canvasWidth,
  canvasHeight,
  exhibitId,
  imageIndex,
}) => {
  const [image] = useImage(url);
  const [rect, setRect] = useState({
    x: 0,
    y: 0,
    width: canvasWidth,
    height: canvasHeight,
  });
  const [imageDimensions, setImageDimensions] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const rectRef = useRef(null);
  const trRef = useRef(null);
  const sendEvent = (imageIndex, rect, imageDimensions) => {
    SendExhibitRequest(
      exhibitId,
      DESTINATION_TYPES_ENUM.TIKTOK,
      EXHIBIT_TYPES_ENUM.SCAN,
      {
        image_index: imageIndex,
        rectangle_x: max(0, rect.x),
        rectangle_y: max(0, rect.y),
        rectangle_width: rect.width,
        rectangle_height: rect.height,
        image_x: imageDimensions.x,
        image_y: imageDimensions.y,
        image_width: imageDimensions.width,
        image_height: imageDimensions.height,
      }
    );
  };
  useEffect(() => {
    if (image) {
      const originalImageWidth = image.width;
      const originalImageHeight = image.height;
      const aspectRatio = originalImageWidth / originalImageHeight;
      let imageActualWidth, imageActualHeight;
      let imageActualX,
        imageActualY = 0;

      if (aspectRatio > canvasWidth / canvasHeight) {
        // Image is wider than 1920x1080
        imageActualWidth = canvasWidth;
        imageActualHeight = imageActualWidth / aspectRatio;
        imageActualY = (canvasHeight - imageActualHeight) / 2;
      } else {
        // Image is taller than 1920x1080
        imageActualHeight = canvasHeight;
        imageActualWidth = imageActualHeight * aspectRatio;
        imageActualX = (canvasWidth - imageActualWidth) / 2;
      }

      setImageDimensions({
        x: imageActualX,
        y: imageActualY,
        width: imageActualWidth,
        height: imageActualHeight,
      });
    }
    sendEvent(imageIndex, rect, imageDimensions);
  }, [image]);

  useEffect(() => {
    // Check if the transformer and rectangle ref is defined
    if (trRef.current && rectRef.current) {
      // Attach the transformer to the rectangle
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [rect]);
  const max = (a, b) => {
    if (a === undefined || b === undefined) {
      return undefined;
    }
    return a > b ? a : b;
  };
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.1;
    const oldWidth = rect.width;
    const oldHeight = rect.height;
    const newWidth = e.evt.deltaY > 0 ? oldWidth / scaleBy : oldWidth * scaleBy;
    const newHeight =
      e.evt.deltaY > 0 ? oldHeight / scaleBy : oldHeight * scaleBy;

    setRect({
      ...rect,
      width: Math.min(canvasWidth, Math.max(5, newWidth)),
      height: Math.min(canvasHeight, Math.max(5, newHeight)),
    });
    sendEvent(
      imageIndex,
      {
        ...rect,
        width: Math.min(canvasWidth, Math.max(5, newWidth)),
        height: Math.min(canvasHeight, Math.max(5, newHeight)),
      },
      imageDimensions
    );
  };

  return (
    <div
      className="outlined-div"
      style={{
        width: canvasWidth + 1,
        height: canvasHeight + 1,
      }}
    >
      <Stage width={canvasWidth} height={canvasHeight} onWheel={handleWheel}>
        <Layer>
          <Image
            image={image}
            x={imageDimensions.x}
            y={imageDimensions.y}
            width={imageDimensions.width}
            height={imageDimensions.height}
          />
          <Rect
            ref={rectRef}
            x={rect.x}
            y={rect.y}
            width={rect.width}
            height={rect.height}
            fill="rgba(255,255,255,0.5)"
            stroke="red"
            strokeWidth={2}
            draggable
            onDragEnd={(e) => {
              setRect({
                ...rect,
                x: max(0, e.target.x()),
                y: max(0, e.target.y()),
              });
              sendEvent(
                imageIndex,
                {
                  ...rect,
                  x: max(0, e.target.x()),
                  y: max(0, e.target.y()),
                },
                imageDimensions
              );
            }}
            onTransformEnd={(e) => {
              const node = e.target;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              node.scaleX(1);
              node.scaleY(1);
              const newWidth = Math.max(5, node.width() * scaleX);
              const newHeight = newWidth * (1080 / 1920);
              setRect({
                x: max(0, node.x()),
                y: max(0, node.y()),
                width: newWidth,
                height: newHeight,
              });
              sendEvent(
                imageIndex,
                {
                  ...rect,
                  x: max(0, node.x()),
                  y: max(0, node.y()),
                  width: newWidth,
                  height: newHeight,
                },
                imageDimensions
              );
            }}
          />
          <Transformer
            ref={trRef}
            flipEnabled={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                return oldBox;
              }
              const aspectRatio = canvasWidth / canvasHeight;
              if (newBox.width / newBox.height !== aspectRatio) {
                newBox.height = newBox.width / aspectRatio;
              }
              return newBox;
            }}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default Viewfinder;
