import "@sharegate/orbit-ui/index.css";
import "../../../App.css";

import { H1, Flex, H2, Text } from "@sharegate/orbit-ui";

export const DashboardHomepage = (AppData) => {
  return (
    <Flex
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column", // Set the Flex direction to column so items stack vertically
        gap: "20px", // Gap between items
      }}
    >
      <H1
        style={{
          textAlign: "center",
          padding: "20px 40px", // Increase padding a bit for aesthetics
          background: "#000",
          color: "#fff", // Make text white for visibility against dark background
          borderRadius: "10px",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        Welcome to the Scan Database!
      </H1>
      <Text as={H2} style={{ maxWidth: "80%", textAlign: "center" }}>
        This is a work in progress. Please report any bugs to the admin.
        <br/>
        <br />
        <Text>Features currently working: 
          <br />
          1) Scans: public and private, you can control this in the upload form
          <br/>
          2) Topics: public only (private in progress)
          <br/>
          3) Discord integration: you can send scans to a discord channel
          <br/>
          4) Search: you can search for scans and topics by tags
          <br/>
          <br/>
          Features in progress:
          <br/>
          1) Topics: private topics
          <br/>
          2) Groups: you can create groups and share scans and topics with them
          <br/>
          3) Discord integration: you can send topics to a discord channel
          <br/>
          4) Auto translate: you can automatically translate scans and topics to other languages
          <br/>
          5) Auto tag: you can automatically tag scans and topics
          </Text>
      </Text>
    </Flex>
  );
};

export default DashboardHomepage;
