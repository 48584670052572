import {
  Text,
  Item,
  TagList,
  Flex,
  MenuTrigger,
  FilterMajorIcon,
  Menu,
  Section,
  FileMajorIcon,
  TextInput,
  Button,
  Icon,
} from "@sharegate/orbit-ui";
import React, {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useRef,
  useEffect,
  RefObject,
  SyntheticEvent,
} from "react";
import { GetTagsRequest } from "../../../SharedFunctions";
import { Exhibit } from "types/Exhibit";

interface CustomTriggerProps {
  setIsOpen: (isOpen: boolean) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

const CustomTrigger = forwardRef<HTMLInputElement, CustomTriggerProps>(
  (props, ref) => {
    const { setIsOpen, onChange, key, ...restProps } = props;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
      setIsOpen(true);
    };

    return (
      <TextInput
        aria-label="Input field"
        ref={ref}
        placeholder="Filter tags"
        icon={<FilterMajorIcon />}
        onFocus={() => setIsOpen(true)}
        onBlur={(event: React.FocusEvent) => {
          if (
            event.relatedTarget === null ||
            event.relatedTarget.nodeName === "BUTTON"
          ) {
            setIsOpen(false);
          }
        }}
        onChange={handleInputChange}
        {...restProps}
      />
    );
  }
);

interface Tag {
  key: string;
  value: string;
  icon: JSX.Element;
}

interface TagGroup {
  title: string;
  items: Tag[];
}

interface TagbarProps {
  filterExhibits: (
    query: string[],
    currentExhibits: Record<string, Exhibit>
  ) => void;
  currentExhibits: Record<string, Exhibit>;
}

export function Tagbar({ filterExhibits, currentExhibits }: TagbarProps) {
  const textInputRef: RefObject<HTMLInputElement> = useRef(null);
  const menuRef: RefObject<HTMLDivElement> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [filterText, setFilterText] = useState("");
  const [tags, setTags] = useState<TagGroup[]>([]);

  const setSelectedKeysWrapper = useCallback(
    (newKeys: string[]) => {
      console.log("Tagbar.tsx: exhibits:", currentExhibits);
      setSelectedKeys(newKeys);
      filterExhibits(newKeys, currentExhibits);
    },
    [currentExhibits, filterExhibits]
  );
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await GetTagsRequest();
        const result = tags.map((tag: Tag) => {
          return {
            key: tag,
            value: tag,
            icon: <FileMajorIcon />,
          };
        });
        setTags([{ title: "Tags", items: result }]);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchData();
  }, []);

  const handleOpenChange = useCallback(
    (event: SyntheticEvent<Element, Event>, isOpen: boolean): void => {
      setIsOpen(!isOpen);
      if (isOpen) {
        setTimeout(() => {
          textInputRef.current?.focus();
        }, 0);
      }
    },
    []
  );

  const handleSelectionChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newKeys: string[]) => {
      setSelectedKeysWrapper(Array.from(newKeys) as string[]);
    },
    [currentExhibits, filterExhibits]
  );

  const handleRemoveTag = useCallback(
    (event: SyntheticEvent<Element, Event>, key: string) => {
      const newSelectedKeys = selectedKeys.filter((x) => x !== key);
      setSelectedKeysWrapper(newSelectedKeys);
    },
    [selectedKeys, setSelectedKeysWrapper]
  );

  const handleClearTags = useCallback(() => {
    setSelectedKeysWrapper([]);
  }, [setSelectedKeysWrapper]);

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(e.target.value);
    },
    []
  );

  const denormalizedTags = useMemo(() => {
    return tags.reduce((acc: Record<string, Tag>, x) => {
      x.items.forEach((item) => {
        acc[item.key] = item;
      });
      return acc;
    }, {});
  }, [tags]);

  const filteredTags = useMemo(() => {
    if (!filterText) return tags; // Return all tags if filter text is empty

    // Filter logic based on text
    return tags
      .map((tagGroup) => ({
        ...tagGroup,
        items: tagGroup.items.filter((item) =>
          item.value.toLowerCase().includes(filterText.toLowerCase())
        ),
      }))
      .filter((tagGroup) => tagGroup.items.length > 0);
  }, [filterText, tags]);


    return (
    <Flex>
      <MenuTrigger
        open={isOpen}
        onBlur={(event: React.FocusEvent) => {
          if (
            event.relatedTarget === null ||
            event.relatedTarget.nodeName === "BUTTON"
          ) {
            //console.log("onBlur", event);
            setIsOpen(false);
          }
        }}
        direction="bottom"
      >
        <CustomTrigger
          variant="secondary"
          marginRight={2}
          setIsOpen={setIsOpen}
          onChange={handleTextChange}
          ref={textInputRef}
        />
         <Menu
          ref={menuRef}
          selectionMode="multiple"
          selectedKeys={selectedKeys}
          onSelectionChange={handleSelectionChange}
          autoFocus={false}
        >
          {filteredTags.map((x) => (
            <Section key={x.title} title={x.title}>
              {x.items.map((y) => (
                <Item key={y.key}>
                  {y.icon}
                  <Text>{y.value}</Text>
                </Item>
              ))}
            </Section>
          ))}
        </Menu>
      </MenuTrigger>
      <TagList onRemove={handleRemoveTag} onClear={handleClearTags}>
        {selectedKeys.map((x) => {
          const tag = denormalizedTags[x];

          return (
            <Item key={tag.key}>
              {tag.icon}
              <Text>{tag.value}</Text>
            </Item>
          );
        })}
      </TagList>
    </Flex>
  );
}
