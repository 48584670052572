import React, { useState, useEffect, useMemo } from "react";
import {
  Inline,
  Item,
  Autocomplete,
  MagnifierMajorIcon,
  Button,
  AddMajorIcon,
  Text,
  Div,
} from "@sharegate/orbit-ui";
import Fuse from "fuse.js";
import {TopicView} from "../../../Topic/Topic";
import { FullTopicEdit } from "../../../Topic/TopicEdit";
import { TopicCard } from "../../../Topic/TopicCard";
import { API_URL_TOPICS, API_URL_SINGLE_TOPIC } from "../../../constants";
import "../ViewerPane.css";

// Custom hook for fetching data
const useFetchTopics = () => {
  const [topics, setTopics] = useState([]);
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(API_URL_TOPICS);
        const data = await response.json();
        setTopics(data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, []);
  return [topics, setTopics];
};

const SearchBar = ({ filterTopics }) => {
  const [searchText, setSearchText] = useState("");
  const planets = [
    "Earth",
    "Jupiter",
    "Mars",
    "Mercury",
    "Neptune",
    "Saturn",
    "Uranus",
  ];

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchText(inputValue);
    filterTopics(inputValue);
  };

  return (
    <Autocomplete
      fluid
      icon={<MagnifierMajorIcon />}
      placeholder="Search for a topic"
      aria-label="Planets"
      align="center"
      onChange={handleInputChange}
      value={searchText}
    >
      {planets.map((planet) => (
        <Item key={planet}>{planet}</Item>
      ))}
    </Autocomplete>
  );
};

export const TopicViewerPane = (AppData) => {
  const [topics, setTopics] = useFetchTopics();

  const [filteredTopics, setFilteredTopics] = useState([]);
  const [activeTopicData, setActiveTopicData] = useState(null);
  const fuseOptions = useMemo(
    () => ({
      keys: ["Tags", "Notes"],
      threshold: 0.3,
    }),
    []
  );

  const handleTopicButtonClick = (data) => {
    setActiveTopicData(null);
    setActiveTopicData(data);
  };

  const closePopup = () => {
    setActiveTopicData(null);
  };

  const filterExhibits = (query) => {
    if (!query) {
      setFilteredTopics(Object.values(topics));
      return;
    }
    const fuse = new Fuse(Object.values(topics), fuseOptions);
    const results = fuse.search(query);
    const filtered = results.map((result) => result.item);
    setFilteredTopics(filtered);
  };

  useEffect(() => {
    setFilteredTopics(Object.values(topics));
  }, [topics]);

  const fetchSingleTopic = (updatedTopic) => {
    setTopics((prevExhibits) => ({
      ...prevExhibits,
      [updatedTopic["TopicID"]]: updatedTopic,
    }));
    let copyUpdatedTopic = { ...updatedTopic };
    copyUpdatedTopic["editMode"] = true;
    setActiveTopicData(copyUpdatedTopic);
  };

  const deleteTopic = (TopicID) => {
    setTopics((prevTopics) => {
      const updatedTopics = { ...prevTopics }; // create a copy of the exhibits object
      delete updatedTopics[TopicID]; // delete the key-value pair where the key is ExhibitID
      return updatedTopics; // return the updated exhibits object
    });
  };

  return (
    <>
      <Div className="centerDiv">
        <div className="newElementButtonDiv">
          <Button
            className="new-scan-button"
            onClick={() => setActiveTopicData({ editMode: true})}
          >
            <AddMajorIcon />
            <Text>New Topic</Text>
          </Button>
        </div>
        <SearchBar filterExhibits={filterExhibits} />
      </Div>
      {activeTopicData &&
        (activeTopicData.editMode ? (
          <FullTopicEdit
            TopicData={activeTopicData}
            updateCardFunction={fetchSingleTopic}
            deleteCardFunction={deleteTopic}
            onClose={closePopup}
          />
        ) : (
          <TopicView
            TopicData={activeTopicData}
            editModeFunction={fetchSingleTopic}
            sendExhibit={AppData.sendExhibit}
            onClose={closePopup}
          />
        ))}

      <Inline>
        {filteredTopics.map((exhibit, i) => (
          <TopicCard
            key={i} // Assuming exhibit has a unique id, fallback to index
            TopicData={exhibit} // Assuming exhibit has a unique id, fallback to index
            delay={i * 0.1}
            onViewScanButtonClick={(data) => handleTopicButtonClick(data)}
            editModeFunction={fetchSingleTopic}
            allowEdit={true}
          />
        ))}
      </Inline>
    </>
  );
};

export default TopicViewerPane;
