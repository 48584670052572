import React from 'react';
import './UnderConstruction.css';

const UnderConstruction = () => (
    <div className="construction-container">
        <h1 className="construction-text">This page is under construction!</h1>
        <div className="construction-animation">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
    </div>
);

export default UnderConstruction;
