import React, { useRef } from "react";
import {
  Card,
  Heading,
  Content,
  Paragraph,
  ButtonGroup,
  Button,
  Header,
  Image,
  Illustration,
  PencilMajorIcon,
  EyeMajorIcon,
  ArrowMajorIcon,
  Text,
  ModalTrigger,
  Modal,
} from "@sharegate/orbit-ui";
import "./Scan.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { API_URL_UPLOAD, getImageURL } from "../constants";

const ScanPopupModal = ({ sendScanFunction, textData }) => {

console.log(textData);  

  return (
    <ModalTrigger>
  <Button variant="secondary">
    <EyeMajorIcon />
    <Text>View</Text>
  </Button>

  <Modal>
    <Heading>Exhibit {textData.ExhibitID}</Heading>
    {textData.Images != null && textData.Images.length > 0 && (<Image src={getImageURL(textData.Images[0])}/>)}
    <Content>
      <Paragraph>
        {textData["English Translation"] != null && textData["English Translation"].length > 0 && (
          <Text>
            English Translation: {textData["English Translation"]}
          </Text>
        )}
      </Paragraph>
      <Paragraph>
        Arabic Text: {textData["Arabic Text"]}
      </Paragraph>
      <Paragraph>
        Reference: {textData.Reference}
      </Paragraph>
      <Paragraph>
        Book: {textData.Book}
      </Paragraph>
      <Paragraph>
        Link: <Text> </Text>  
         <a href={textData.Link} target="_blank" rel="noopener noreferrer">
          {textData.Link}
        </a>
      </Paragraph>
      <Paragraph>
        Tags: {textData.Tags}
      </Paragraph>
      <Paragraph>
        Related Exhibits: {textData["Related Exhibits"]}
      </Paragraph>
      <Paragraph>
        Notes: {textData.Notes}
      </Paragraph>
    </Content>
    <ButtonGroup>
      <Button variant="primary" onClick={() => sendScanFunction(textData["ExhibitID"])}><ArrowMajorIcon/><Text>Send</Text></Button>
    </ButtonGroup>
  </Modal>
</ModalTrigger>

  );
};

export default ScanPopupModal;
