import "@sharegate/orbit-ui/index.css";
import "../../App.css";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import StickyHeader from "../../Header/Header.js";
import {SendExhibitRequest} from "../../SharedFunctions";
import DashboardHomepage from "./homepage/homepage";
import DashboardTopics from "./topics/Topics";
import DashboardScans from "./scans/Scans";
import UnderConstruction from "../construction/UnderConstruction";
import { API_URL_EXHIBITS_ALL, API_URL_EXHIBITS_MY, API_URL_EXHIBITS_PUBLIC, DESTINATION_TYPES_ENUM } from "../../constants";
import { Routes, Route } from "react-router-dom";

export const Dashboard = ({user}) => {

  const [discordChannel, setDiscordChannel] = useState("");
  const [destinationType, setDestinationType] = useState(DESTINATION_TYPES_ENUM.DISCORD);

  const sendExhibit = (data, exhibitType) => {
    SendExhibitRequest(data, discordChannel, exhibitType);
  };

  const AppData = { discordChannel, setDiscordChannel, sendExhibit, destinationType, setDestinationType };
  const location = useLocation();
  console.log("Current location:", location.pathname); // Log current location

  return (
    <>
      <div>
        <StickyHeader {...{ discordChannel, setDiscordChannel, user, destinationType, setDestinationType }} />
      </div>
      <Routes>
        <Route index path="" element={<DashboardHomepage {...AppData} />} />
        <Route path="/app/scans/my" element={<DashboardScans {...AppData} exhibits_url={API_URL_EXHIBITS_MY} />} />
        <Route path="/app/scans/shared" element={<UnderConstruction />} />
        <Route path="/app/scans/public" element={<DashboardScans {...AppData} exhibits_url={API_URL_EXHIBITS_PUBLIC} />} />
        <Route path="/app/scans/all" element={<DashboardScans {...AppData} exhibits_url={API_URL_EXHIBITS_ALL} />} />
        <Route path="/app/topics/all" element={<UnderConstruction />} />
        <Route path="/app/topics/my" element={<UnderConstruction />} />
        <Route path="/app/topics/shared" element={<UnderConstruction />} />
        <Route path="/app/topics/public" element={<DashboardTopics {...AppData}/>} />
        <Route path="/app/groups/all" element={<UnderConstruction />} />
        <Route path="/app/groups/my" element={<UnderConstruction />} />
        <Route path="/app/groups/shared" element={<UnderConstruction />} />
        <Route path="/app/groups/public" element={<UnderConstruction />} />
        <Route path="/app/*" element={<UnderConstruction {...{ discordChannel, setDiscordChannel, sendExhibit }} />} />
      </Routes>
    </>
  );
};

export default Dashboard;