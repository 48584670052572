import {
  Card,
  Heading,
  Content,
  Paragraph,
  ButtonGroup,
  Button,
  Header,
  PencilMajorIcon,
  EyeMajorIcon,
  Text,
  Div,
} from "@sharegate/orbit-ui";
import "./Topic.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

export const TopicCard = ({
  TopicData,
  delay,
  onViewScanButtonClick,
  allowEdit,
}) => {

  // TODO - don't allow edit if TopicData says it's not editable

  const handleButtonClick = (editMode) => {
    TopicData.editMode = editMode;
    onViewScanButtonClick(TopicData);

    // Scroll to the top of the div
    const container = document.querySelector(".ScanViewerPaneDiv");
    if (container) {
      container.scrollTop = 0;
    }
  };

  const fieldsToRender = [
    { key: "Text", maxLength: 300 },
    { key: "Tags", maxLength: 50 },
    { key: "Notes", maxLength: 60 },
    { key: "Related Scans", maxLength: 50 },
  ].filter((field) => {
    const fieldValue = TopicData[field.key];
    return (
      fieldValue &&
      (typeof fieldValue === "string" ? fieldValue.trim() !== "" : true)
    );
  });
  const tags = TopicData.Tags || [];
  const relatedScans = TopicData["Related Scans"] || [];

  return (
    <Div>
      <Card className="slide-up" style={{ animationDelay: `${delay}s` }}>
        
        <Heading>{TopicData.Title}</Heading>
        <Header></Header>
        <Content>
          {fieldsToRender.map((field) => (
            <Paragraph key={field.key}>
              <strong>{field.key}: </strong>
              {field.key === "Tags" ? (
                <span>{tags.join(", ")}</span>
              ) : field.key === "Related Scans" ? (
                <span>{relatedScans.join(", ")}</span>
              ) : field.key === "Link" ? (
                <a
                  href={TopicData[field.key]}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "lightgrey" }}
                >
                  {TopicData[field.key].length > field.maxLength ? (
                    <span>
                      {TopicData[field.key].slice(0, field.maxLength)}...
                    </span>
                  ) : (
                    <span>{TopicData[field.key]}</span>
                  )}
                </a>
              ) : TopicData[field.key].length > field.maxLength ? (
                <span>{TopicData[field.key].slice(0, field.maxLength)}...</span>
              ) : (
                <span>{TopicData[field.key]}</span>
              )}
            </Paragraph>
          ))}
        </Content>

        <ButtonGroup>
          {onViewScanButtonClick !== null && (
            <>
              {allowEdit && (
                <Button
                  variant="secondary"
                  onClick={() => handleButtonClick(true)}
                >
                  <PencilMajorIcon />
                  <Text>Edit</Text>
                </Button>
              )}

              <Button
                variant="secondary"
                onClick={() => handleButtonClick(false)}
              >
                <EyeMajorIcon />
                <Text>View</Text>
              </Button>
            </>
          )}
        </ButtonGroup>
      </Card>
    </Div>
  );
};

export default TopicCard;
