import React, { useRef } from "react";
import {
    Card,
    Heading,
    Content,
    Paragraph,
    ButtonGroup,
    Button,
    TrashMajorIcon,
    Text,
    Box,
    as,
    CrossMajorIcon,
    Modal,
    ModalTrigger,
    TextArea,
    Field,
    Label,
    TextInput,
    HelpMessage,
    FileMajorIcon,
} from "@sharegate/orbit-ui";
import { useState } from "react";
import axios from "axios";

import "./Topic.css";
import {
    getTopicURL,
    API_URL_TOPICS,
} from "../constants";

export const FullTopicEdit = ({
    TopicData,
    updateCardFunction,
    deleteCardFunction,
    onClose,
}) => {
    const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false); // Add a state variable to track loading
    const [deleteButtonIsLoading, setDeleteButtonIsLoading] = useState(false); // Add a state variable to track loading

    const handleClose = () => {
        onClose(); // Call the onClose function to close the FullScan
    };

    const Div = as(Box, "div");

    const {
        TopicID,
        Title,
        Tags,
        RelatedExhibits,
        RelatedTopics,
        Notes,
    } = TopicData;
    const TextData = TopicData.Text;

    const titleRef = useRef(null);
    const textRef = useRef(null);
    const tagsRef = useRef(null);
    const notesRef = useRef(null);


    const SaveScan = async () => {
        // Prepare the payload in the required format
        let topicData = {
            TopicID,
            Title: titleRef.current.value,
            TextData: textRef.current.value,
            Tags: tagsRef.current.value.split(","), // Split tags string into an array
            "Related Exhibits": [], // Split related exhibits string into an array
            Notes: notesRef.current.value,
            Public: true,
        };

        setSaveButtonIsLoading(true); // Start loading
        let response; // Declare response here

        try {
            // Make a POST request here, replace the URL and data with your API endpoint and data
            response = await axios.post(API_URL_TOPICS, topicData);
        } catch (error) {
            console.error(error);
        } finally {
            setSaveButtonIsLoading(false); // End loading when the request finishes, whether it fails or succeeds
            if (response) {
                let currentExhibitData = response.data;
                updateCardFunction(currentExhibitData); // Call the updateCardFunction to update the card
                TopicData = currentExhibitData; // Update the textData variable
            }
        }
    };

    const DeleteScan = async () => {
        // Prepare the payload in the required format

        setDeleteButtonIsLoading(true); // Start loading
        let response; // Declare response here
        try {
            // Make a POST request here, replace the URL and data with your API endpoint and data
            response = await axios.delete(getTopicURL(TopicID));
        } catch (error) {
            console.error(error);
        } finally {
            setDeleteButtonIsLoading(false); // End loading when the request finishes, whether it fails or succeeds
            if (response) {
                let currentExhibitData = response.data;
                deleteCardFunction(response.data); // Call the updateCardFunction to update the card
                TopicData = currentExhibitData; // Update the textData variable
                onClose();
            }
        }
    };

    return (
        <Div className="fullscan-container-div">
            <br />
            <Heading>Topic {TopicID}</Heading>
            <Content>
                <Div padding={"5px"} margin={"10px"}>

                    <Field fluid>
                        <Label>Title</Label>
                        <TextInput
                            ref={titleRef}
                            placeholder="Proofs of Prophethood"
                            defaultValue={Title}
                        />
                        <HelpMessage>
                            Topic Title
                        </HelpMessage>
                    </Field>

                    <br />
                    <br />

                    <Field fluid>
                        <Label>Text</Label>
                        <TextArea
                            ref={textRef}
                            placeholder="The evidence for the validity of Islam and the truthfulness of the Prophethood of the Prophet Muhammad (blessings and peace of Allah be upon him) is abundant and can hardly be enumerated."
                            defaultValue={TextData}
                        />
                        <HelpMessage>
                            Translation of the Arabic text into English.
                        </HelpMessage>
                    </Field>

                    <br />
                    <br />

                    <Field fluid>
                        <Label>Tags</Label>
                        <TextInput
                            ref={tagsRef}
                            placeholder="intentions, omar, actions"
                            defaultValue={Tags}
                        />
                        <HelpMessage>
                            Some tags with which to easily look up the narration.
                        </HelpMessage>
                    </Field>
                    <br />
                    <br />
                    <Paragraph>
                        <strong>Related Exhibits:</strong> {RelatedExhibits}
                    </Paragraph>
                    <br />
                    <br />
                    <Field fluid>
                        <Label>Notes</Label>
                        <TextArea
                            ref={notesRef}
                            placeholder="This hadith can be used to show that actions of a person cannot be held against them if they are ignorant of the sin or incorrectness of their actions."
                            defaultValue={Notes}
                        />
                        <HelpMessage>
                            Any notes that go along with this scan if used.
                        </HelpMessage>
                    </Field>
                    <br />
                    <br />
                </Div>
            </Content>
            <Text></Text>
            <ButtonGroup>
                <Button loading={saveButtonIsLoading} onClick={SaveScan}>
                    <FileMajorIcon />
                    <Text>Save</Text>
                </Button>
                <Button variant="negative" onClick={handleClose}>
                    <CrossMajorIcon />
                    <Text>Close</Text>
                </Button>
                {TopicID && (
                    <ModalTrigger>
                        <Button variant="negative">
                            <TrashMajorIcon />
                            <Text>Delete</Text>
                        </Button>
                        <Modal>
                            <Heading>Delete Topic</Heading>
                            <Content>Are you sure you want to delete this topic?</Content>
                            <ButtonGroup>
                                <Button
                                    loading={deleteButtonIsLoading}
                                    variant="negative"
                                    onClick={DeleteScan}
                                >
                                    <TrashMajorIcon />
                                    <Text>Delete</Text>
                                </Button>
                            </ButtonGroup>
                        </Modal>
                    </ModalTrigger>
                )}
            </ButtonGroup>
        </Div>
    );
};

export default FullTopicEdit;
