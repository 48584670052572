
import '@sharegate/orbit-ui/index.css';
import 'App.css';
import '../ViewerPane.css'
import {
  Div,
} from "@sharegate/orbit-ui";
import ScanViewerPane from './ScanViewerPane';

export const DashboardScans = (AppData: any) => {
  return (
    <Div className="ViewerPaneDiv">
      <ScanViewerPane {...AppData}/>
    </Div>
  );
};

export default DashboardScans;
