import "@sharegate/orbit-ui/index.css";
import "../../../App.css";
import { useEffect, useState } from "react";
import { Div } from "@sharegate/orbit-ui";
import StickyHeader from "../../../Header/Header.js";
import TopicViewerPane from "./TopicViewerPane";
import {GetExhibitRequest} from "../../../SharedFunctions";

export const DashboardTopics = (AppData) => {

  return (
    <Div className="ViewerPaneDiv">
      <TopicViewerPane {...AppData} />
    </Div>
  );
};

export default DashboardTopics;
