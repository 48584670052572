import React, { useRef } from "react";
import {
  TooltipTrigger,
  Tooltip,
  Lozenge,
  Heading,
  Content,
  Paragraph,
  Text,
} from "@sharegate/orbit-ui";
import { useEffect, useState } from "react";
import "../ExpandedScan/Scan.css";
import "@uppy/core/dist/style.min.css";
import { getImageURL } from "../constants";
import "@uppy/dashboard/dist/style.min.css";
import { GetExhibitRequest } from "../SharedFunctions";
import ScanPopupModal from "../ExpandedScan/ScanPopupModal";

export const ScanMention = ({ item, exhibitID }) => {
  exhibitID = exhibitID.padStart(6, "0");
  const [textData, setData] = useState(null); // Create a state variable to hold your data


  useEffect(() => {
    const fetchData = async () => {
      const resp = await GetExhibitRequest(exhibitID); // Use the prop exhibitID
      setData(resp); // Save the response to your state variable
    };

    fetchData();
  }, [exhibitID]); // Add exhibitID as a dependency, so the effect runs again if it changes

  return (
    <>
    <Text>{" "}</Text>
      <TooltipTrigger>
        <Lozenge onClick={() => console.log("sdf")}>
          Exhibit {item.content}
        </Lozenge>
        <>
          {textData && (
            <Tooltip>
              <Text><Heading>Exhibit {textData.ExhibitID}</Heading>
              <Content>
                <Paragraph>
                  {textData["English Translation"].length > 0 && (
                    <Text>
                      English Translation: {textData["English Translation"]}
                    </Text>
                  )}
                </Paragraph>
                <Paragraph>Arabic Text: {textData["Arabic Text"]}</Paragraph>
                <Paragraph>Reference: {textData.Reference}</Paragraph>
                <Paragraph>Book: {textData.Book}</Paragraph>
                <Paragraph>
                  Link: <Text> </Text>
                  <a
                    href={textData.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {textData.Link}
                  </a>
                </Paragraph>
                <Paragraph>Tags: {textData.Tags}</Paragraph>
                <Paragraph>
                  Related Exhibits: {textData["Related Exhibits"]}
                </Paragraph>
                <Paragraph>Notes: {textData.Notes}</Paragraph>
              </Content></Text>
            </Tooltip>
          )}
        </>
      </TooltipTrigger>
      <Text>{" "}</Text>
    </>
  );
};

export default ScanMention;
