import '@sharegate/orbit-ui/index.css';
import './App.css';
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import Dashboard from './pages/dashboard/ScanManagerDashboard';
import LoginPage from './pages/loginpage/LoginPage';
import { USER_URL } from './constants';

export const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(USER_URL)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setUser(data);
        } else {
          console.log("Failed to get user data, got" + data);
          setUser(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []); // Empty array means this effect runs once on mount and not on updates

  if (loading) {
    return <LoginPage />; // Or some loading spinner
  }

  return (
    <Router>
      <Routes>
        <Route path="/*" element={user ? <Dashboard {...{ user }} /> : <LoginPage />} />
      </Routes>
    </Router>
  );
};

export default App;
