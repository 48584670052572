export interface Exhibit {
  exhibitId: string;               // json:"exhibitId"
  englishTranslation: string;      // json:"englishTranslation"
  arabicText: string;              // json:"arabicText"
  reference: string;               // json:"reference"
  link: string;                    // json:"link"
  tags: string[];                  // json:"tags"
  book: string;                    // json:"book"
  relatedScans: string[];          // json:"relatedScans"
  notes: string;                   // json:"notes"
  images: string[];                // json:"images"
  public: boolean;                 // json:"public"
}

  
export const isExhibit = (obj: any): obj is Exhibit => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.exhibitId === 'string' &&
    typeof obj.englishTranslation === 'string' &&
    typeof obj.arabicText === 'string' &&
    typeof obj.reference === 'string' &&
    typeof obj.link === 'string' &&
    Array.isArray(obj.tags) &&
    obj.tags.every((tag: any) => typeof tag === 'string') &&
    typeof obj.book === 'string' &&
    Array.isArray(obj.relatedScans) &&
    obj.relatedScans.every((scan: any) => typeof scan === 'string') &&
    typeof obj.notes === 'string' &&
    Array.isArray(obj.images) &&
    obj.images.every((image: any) => typeof image === 'string') &&
    typeof obj.public === 'boolean'
  );
};

export const exhibitIncorrectFields = (obj: any): string[] => {
  const incorrectFields: string[] = [];

  if (typeof obj.exhibitId !== 'string') {
    incorrectFields.push('exhibitId');
  }

  if (typeof obj.englishTranslation !== 'string') {
    incorrectFields.push('englishTranslation');
  }

  if (typeof obj.arabicText !== 'string') {
    incorrectFields.push('arabicText');
  }

  if (typeof obj.reference !== 'string') {
    incorrectFields.push('reference');
  }

  if (typeof obj.link !== 'string') {
    incorrectFields.push('link');
  }

  if (!Array.isArray(obj.tags) || !obj.tags.every((tag: any) => typeof tag === 'string')) {
    incorrectFields.push('tags');
  }

  if (typeof obj.book !== 'string') {
    incorrectFields.push('book');
  }

  if (!Array.isArray(obj.relatedScans) || !obj.relatedScans.every((scan: any) => typeof scan === 'string')) {
    incorrectFields.push('relatedScans');
  }

  if (typeof obj.notes !== 'string') {
    incorrectFields.push('notes');
  }

  if (!Array.isArray(obj.images) || !obj.images.every((image: any) => typeof image === 'string')) {
    incorrectFields.push('images');
  }

  if (typeof obj.public !== 'boolean') {
    incorrectFields.push('public');
  }

  return incorrectFields;
};