import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Heading,
  Content,
  Paragraph,
  ButtonGroup,
  Button,
  Header,
  Image,
  Illustration,
  PencilMajorIcon,
  EyeMajorIcon,
  ArrowMajorIcon,
  Text,
  Div,
} from "@sharegate/orbit-ui";
import "./Scan.css";
import "@uppy/core/dist/style.min.css";
import { getImageURL } from "../constants";
import "@uppy/dashboard/dist/style.min.css";
import { EXHIBIT_TYPES_ENUM } from "../constants";
import ScanPopupModal from "./ScanPopupModal";
import { Exhibit } from "types/Exhibit";

interface ScanCardProps {
  textData: Exhibit;
  delay: number;
  onViewScanButtonClick: (data: any, editMode: boolean) => void;
  sendExhibit: (exhibitId: string, type: string) => void;
  useModal: boolean;
  allowEdit: boolean;
}

export const ScanCard: React.FC<ScanCardProps> = ({
  textData,
  delay,
  onViewScanButtonClick,
  sendExhibit,
  useModal,
  allowEdit,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleButtonClick = (editMode: boolean) => {
    onViewScanButtonClick(textData, editMode);

    const container = document.querySelector(".ScanViewerPaneDiv");
    if (container) {
      container.scrollTop = 0;
    }
  };

  interface FieldToRender {
    key: keyof Exhibit;
    maxLength: number;
    displayName: String;
  }

  const fieldsToRender: FieldToRender[] = [
    { key: "englishTranslation", maxLength: 125, displayName: "English Translation" } as FieldToRender,
    { key: "reference", maxLength: 35, displayName: "Reference" } as FieldToRender,
    { key: "tags", maxLength: 50, displayName: "Tags" } as FieldToRender,
    { key: "arabicText", maxLength: 65, displayName: "Arabic Text" } as FieldToRender,
    { key: "link", maxLength: 50, displayName: "Link" } as FieldToRender,
    { key: "notes", maxLength: 60, displayName: "Notes" } as FieldToRender,
    { key: "relatedScans", maxLength: 50, displayName: "Related Scans" } as FieldToRender,
  ].filter((field) => {
    const fieldValue = textData[field.key];
  
    // Check if the fieldValue is a string or an array of strings
    if (typeof fieldValue === "string") {
      return fieldValue.trim() !== ""; // For strings, ensure it’s not empty
    }
  
    if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0; // For arrays, ensure it has at least one element
    }
  
    // Allow other types as valid (boolean, numbers, etc.), you can customize this check
    return !!fieldValue;
  });

  const images = textData.images || [];
  const tags = textData.tags || [];
  const relatedScans = textData.relatedScans || [];

  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <Div>
      <Card className="slide-up" style={{ animationDelay: `${delay}s` }}>
        {images.length > 0 && (
          <Illustration backgroundColor="alias-accent-dark">
            <Image
              src={getImageURL(images[currentImageIndex])}
              alt="Couldn't load image"
            />
          </Illustration>
        )}
        <Heading>Exhibit {textData.exhibitId}</Heading>
        <Header children={undefined}></Header>
        <Content>
        {fieldsToRender.map((field) => {
  const fieldValue = textData[field.key];

  const renderTags = () => <span>{tags.join(", ")}</span>;

  const renderRelatedScans = () => <span>{relatedScans.join(", ")}</span>;

  const renderLink = () => (
    <a
      href={typeof fieldValue === 'string' ? fieldValue : ""}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "lightgrey" }}
    >
      {typeof fieldValue === 'string' && fieldValue.length > field.maxLength
        ? `${fieldValue.slice(0, field.maxLength)}...`
        : fieldValue}
    </a>
  );

  const renderDefault = () => (
    <span>
      {typeof fieldValue === 'string' && fieldValue.length > field.maxLength
        ? `${fieldValue.slice(0, field.maxLength)}...`
        : fieldValue}
    </span>
  );

  const renderFieldValue = () => {
    switch (field.key) {
      case "tags":
        return renderTags();
      case "relatedScans":
        return renderRelatedScans();
      case "link":
        return renderLink();
      default:
        return renderDefault();
    }
  };

  return (
    <Paragraph key={field.key}>
      <strong>{field.displayName}: </strong>
      {renderFieldValue()}
    </Paragraph>
  );
})}

        </Content>

        <ButtonGroup>
          {onViewScanButtonClick !== null && (
            <>
              {allowEdit && (
                <Button
                  variant="secondary"
                  onClick={() => handleButtonClick(true)}
                >
                  <PencilMajorIcon />
                  <Text>Edit</Text>
                </Button>
              )}
              <>
                {useModal ? (
                  <ScanPopupModal
                    sendScanFunction={sendExhibit}
                    textData={textData}
                  />
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => handleButtonClick(false)}
                  >
                    <EyeMajorIcon />
                    <Text>View</Text>
                  </Button>
                )}
              </>
            </>
          )}

          <Button
            onClick={() =>
              sendExhibit(textData.exhibitId, EXHIBIT_TYPES_ENUM.SCAN)
            }
          >
            <ArrowMajorIcon />
            <Text>Send</Text>
          </Button>
        </ButtonGroup>
      </Card>
    </Div>
  );
};

export default ScanCard;
