import React, {
  useState,
  useEffect,
  useMemo,
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  Inline,
  Button,
  AddMajorIcon,
  Text,
  Div,
} from "@sharegate/orbit-ui";
import { FullScan } from "../../../ExpandedScan/Scan";
import { FullScanEdit } from "../../../ExpandedScan/ScanEdit";
import { ScanCard } from "../../../ExpandedScan/ScanCard";
import { Tagbar } from "../Tagbar/Tagbar";
import "../ViewerPane.css";
import { Exhibit, exhibitIncorrectFields, isExhibit } from "types/Exhibit";

interface AppDataProps {
  exhibits_url: string;
  sendExhibit: (data: any) => void;
  destinationType: string;
}


export const ScanViewerPane: FC<AppDataProps> = ({
  exhibits_url,
  sendExhibit,
  destinationType,
}) => {
  const [exhibits, setExhibits] = useState<Record<string, Exhibit>>({});
  const [filteredExhibits, setFilteredExhibits] =
    useState<Record<string, Exhibit>>(exhibits);
  const [activeScanData, setActiveScanData] = useState<any>(null); // Replace 'any' with a specific type

  const useFetchExhibits = (
    url: string,
  ) => {
    useEffect(() => {
      const fetchExhibits = async () => {
        try {
          const response = await fetch(url);
          const data: Record<string, Exhibit> = await response.json();
          for (const key in data) {
            if (!isExhibit(data[key])) {
              console.warn(`Invalid exhibit data for key ${key}`, data[key]);
            }
          }
          setExhibits(data); // Call the external setExhibits
          setFilteredExhibits(data);
        } catch (error) {
          console.error("Error fetching exhibits:", error);
        }
      };
      fetchExhibits();
    }, [url]);
  };

  useEffect(() => {
    console.log('Exhibits updated:', exhibits);
  }, [exhibits]);

  useFetchExhibits(exhibits_url);

  const handleScanButtonClick = (data: any, editMode: boolean) => {
    // Replace 'any' with a specific type
    setActiveScanData({ ...data, editMode: editMode });
  };

  const closePopup = () => {
    setActiveScanData(null);
  };

  const filterExhibits = useCallback(
    (query: string[], currentExhibits: Record<string, Exhibit>) => {
      if (!query || query.length === 0) {
        setFilteredExhibits(currentExhibits);
        return;
      }
  
      const result: Record<string, Exhibit> = {};
  
      Object.entries(currentExhibits).forEach(([key, exhibit]) => {
        console.log(exhibit);
        if (query.every((tag) => exhibit.tags.includes(tag))) {
          result[key] = exhibit;
        }
      });
  
      setFilteredExhibits(result);
    },
    [] // No dependencies needed if not using variables from outer scope
  );
  

  const fetchSingleExhibit = (updatedExhibit: any) => {
    // Replace 'any' with a specific type
    let copyUpdatedExhibit = { ...updatedExhibit, editMode: true };
    setActiveScanData(copyUpdatedExhibit);
  };

  const deleteExhibit = (ExhibitID: string) => {
    const updatedExhibits = { ...exhibits };
    delete updatedExhibits[ExhibitID];
    setExhibits(updatedExhibits);
  };

  return (
    <>
      <Div className="centerDiv">
        <div className="newElementButtonDiv">
          <Button
            className="new-scan-button"
            onClick={() => setActiveScanData({ editMode: true, Images: [] })}
          >
            <AddMajorIcon />
            <Text>New Scan</Text>
          </Button>
        </div>
      </Div>
      <Div className="centerDiv">
        <div className="tagbarDiv">
          <Tagbar filterExhibits={filterExhibits} currentExhibits={exhibits} />
        </div>
      </Div>
      {activeScanData &&
        (activeScanData.editMode ? (
          <FullScanEdit
            textData={activeScanData}
            updateCardFunction={fetchSingleExhibit}
            deleteCardFunction={deleteExhibit}
            onClose={closePopup}
          />
        ) : (
          <FullScan
            textData={activeScanData}
            editModeFunction={fetchSingleExhibit}
            sendScanFunction={sendExhibit}
            onClose={closePopup}
            onButtonClick={undefined}
            destinationType={destinationType}
          />
        ))}
      <ScanCardsArea filteredExhibits={filteredExhibits} sendExhibit={sendExhibit} handleScanButtonClick={handleScanButtonClick} />
    </>
  );
};

const ScanCardsArea: FC<{
  filteredExhibits: Record<string, Exhibit>;
  sendExhibit: (data: any) => void;
  handleScanButtonClick: (data: any, editMode: boolean) => void;
}> = ({ filteredExhibits, sendExhibit, handleScanButtonClick }) => {
  return (
    <Inline>
      {Object.values(filteredExhibits).map((exhibit: any, i: number) => (
        <ScanCard
          key={i}
          textData={exhibit}
          delay={i * 0.01}
          onViewScanButtonClick={handleScanButtonClick}
          sendExhibit={sendExhibit}
          allowEdit={true}
          useModal={false}
        />
      ))}
    </Inline>
  );
};

export default ScanViewerPane;
